import React from 'react'
import { Link } from 'gatsby'
import Layout from '../components/Layout'
import ImageGallery from '../components/ImageGallery'
import FullWidthImage from '../components/FullWidthImage'

// eslint-disable-next-line
export const ProductPageTemplate = () => {
  return (
    <div className="content">
      <FullWidthImage />
      <section className="section section--gradient">
        <ul className="list products-list">
          <ImageGallery />
        </ul>
        <div className="container">
          <Link
            to="/"
            className="link"
            style={{
              verticalAlign: 'middle',
            }}
          >
            Повернутися на головну <span>&#8594;</span>
          </Link>
        </div>
      </section>
    </div>
  )
}

const ProductPage = () => {
  return (
    <Layout>
      <ProductPageTemplate />
    </Layout>
  )
}

export default ProductPage
