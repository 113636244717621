import React from 'react'
import PropTypes from 'prop-types'

export default function FullWidthImage(props) {
  const title = 'Галерея робіт'

  return (
    <React.Fragment>
      <div
        style={{
          display: 'grid',
          alignItems: 'center',
          background: 'rgb(251, 191, 36)',
        }}
      >
        {title && (
          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: '1/1',
              position: 'relative',
              // This centers the other elements inside the hero component
              placeItems: 'center',
              display: 'grid',
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <h1
                className="has-text-weight-bold is-size-3-mobile is-size-2-tablet is-size-1-widescreen"
                style={{
                  boxShadow: '#fbbf24 0.5rem 0px 0px, #fbbf24 -0.5rem 0px 0px',
                  backgroundColor: '#fbbf24',
                  lineHeight: '1',
                  padding: '0.25em',
                  margin: '1em',
                }}
              >
                {title}
              </h1>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  )
}

FullWidthImage.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.number,
}
