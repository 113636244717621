import React from 'react'
import gallery from '../pages/products/gallery.json'

const ImageGallery = () => {
  // const data = useStaticQuery(graphql`
  //   query CloudinaryImage {
  //     allCloudinaryMedia {
  //       edges {
  //         node {
  //           secure_url
  //         }
  //       }
  //     }
  //   }
  // `)

  const clImages = gallery.intro.blurbs
  return (
    <>
      {clImages.map((image, index) => (
        <li className="item">
          <div className="image-item" key={`${index}-cl`}>
            <img src={image.image} alt="" />
          </div>
        </li>
      ))}
    </>
  )
}
export default ImageGallery
